import { HttpClient, HttpResponse } from "@angular/common/http";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { Observable, catchError, map, throwError } from "rxjs";
import { environment } from "../../environments/environment";
import { isPlatformBrowser, isPlatformServer } from "@angular/common";

@Injectable({
  providedIn: "root",
})
export class SharedServiceService {
  private branchCode: any;
  private responseData: any;
  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  // All get APIs

  getTherapiesPackages(): Observable<any> {
    const url = `${environment.apiLoginUrl}/therapiesST/`;

    if (isPlatformServer(this.platformId)) {
      // SSR: Handle as text and manually parse JSON
      return this.http
        .get(url, { observe: "response", responseType: "text" })
        .pipe(
          map((response: HttpResponse<string>) => {
            if (response.body) {
              const trimmedData = response.body.trim();
              if (trimmedData.startsWith("{") || trimmedData.startsWith("[")) {
                // Manually parse the JSON and return it
                return JSON.parse(trimmedData);
              } else {
                // Throw an error if the response is not in JSON format
                throw new Error("Non-JSON response received");
              }
            }
            // In case there is no body in the response
            return {};
          }),
          catchError((error: any) => {
            // console.error('SSR Error:', error);
            return throwError(error);
          })
        );
    } else if (isPlatformBrowser(this.platformId)) {
      // Browser: Let HttpClient handle the JSON response
      return this.http.get<any>(url).pipe(
        map((user: any) => {
          return user;
        }),
        catchError((error: any) => {
          // console.error('Browser Error:', error);
          return throwError(error);
        })
      );
    }

    // Provide a fallback in case neither condition is met
    return throwError("Unknown platform");
  }
  getHealthBenefitsPackages(): Observable<any> {
    const url = `${environment.apiLoginUrl}/healthBenefitsST/`;

    if (isPlatformServer(this.platformId)) {
      // SSR: Handle as text and manually parse JSON
      return this.http
        .get(url, { observe: "response", responseType: "text" })
        .pipe(
          map((response: HttpResponse<string>) => {
            if (response.body) {
              const trimmedData = response.body.trim();
              if (trimmedData.startsWith("{") || trimmedData.startsWith("[")) {
                // Manually parse the JSON and return it
                return JSON.parse(trimmedData);
              } else {
                // Throw an error if the response is not in JSON format
                throw new Error("Non-JSON response received");
              }
            }
            // In case there is no body in the response
            return {};
          }),
          catchError((error: any) => {
            // console.error('SSR Error:', error);
            return throwError(error);
          })
        );
    } else if (isPlatformBrowser(this.platformId)) {
      // Browser: Let HttpClient handle the JSON response
      return this.http.get<any>(url).pipe(
        map((user: any) => {
          return user;
        }),
        catchError((error: any) => {
          // console.error('Browser Error:', error);
          return throwError(error);
        })
      );
    }

    // Provide a fallback in case neither condition is met
    return throwError("Unknown platform");
  }
  getBlogs(): Observable<any> {
    const url = `${environment.apiLoginUrl}/blogST/blogList`;

    if (isPlatformServer(this.platformId)) {
      // SSR: Handle as text and manually parse JSON
      return this.http
        .get(url, { observe: "response", responseType: "text" })
        .pipe(
          map((response: HttpResponse<string>) => {
            if (response.body) {
              const trimmedData = response.body.trim();
              if (trimmedData.startsWith("{") || trimmedData.startsWith("[")) {
                // Manually parse the JSON and return it
                return JSON.parse(trimmedData);
              } else {
                // Throw an error if the response is not in JSON format
                throw new Error("Non-JSON response received");
              }
            }
            // In case there is no body in the response
            return {};
          }),
          catchError((error: any) => {
            // console.error('SSR Error:', error);
            return throwError(error);
          })
        );
    } else if (isPlatformBrowser(this.platformId)) {
      // Browser: Let HttpClient handle the JSON response
      return this.http.get<any>(url).pipe(
        map((user: any) => {
          return user;
        }),
        catchError((error: any) => {
          // console.error('Browser Error:', error);
          return throwError(error);
        })
      );
    }

    // Provide a fallback in case neither condition is met
    return throwError("Unknown platform");
  }
  getOffers(): Observable<any> {
    const url = `${environment.apiLoginUrl}/SPAOfferST/list `;

    if (isPlatformServer(this.platformId)) {
      // SSR: Handle as text and manually parse JSON
      return this.http
        .get(url, { observe: "response", responseType: "text" })
        .pipe(
          map((response: HttpResponse<string>) => {
            if (response.body) {
              const trimmedData = response.body.trim();
              if (trimmedData.startsWith("{") || trimmedData.startsWith("[")) {
                // Manually parse the JSON and return it
                return JSON.parse(trimmedData);
              } else {
                // Throw an error if the response is not in JSON format
                throw new Error("Non-JSON response received");
              }
            }
            // In case there is no body in the response
            return {};
          }),
          catchError((error: any) => {
            // console.error('SSR Error:', error);
            return throwError(error);
          })
        );
    } else if (isPlatformBrowser(this.platformId)) {
      // Browser: Let HttpClient handle the JSON response
      return this.http.get<any>(url).pipe(
        map((user: any) => {
          return user;
        }),
        catchError((error: any) => {
          // console.error('Browser Error:', error);
          return throwError(error);
        })
      );
    }

    // Provide a fallback in case neither condition is met
    return throwError("Unknown platform");
  }
  getTestimonial(): Observable<any> {
    const url = `${environment.apiLoginUrl}/testimonialST/ `;

    if (isPlatformServer(this.platformId)) {
      // SSR: Handle as text and manually parse JSON
      return this.http
        .get(url, { observe: "response", responseType: "text" })
        .pipe(
          map((response: HttpResponse<string>) => {
            if (response.body) {
              const trimmedData = response.body.trim();
              if (trimmedData.startsWith("{") || trimmedData.startsWith("[")) {
                // Manually parse the JSON and return it
                return JSON.parse(trimmedData);
              } else {
                // Throw an error if the response is not in JSON format
                throw new Error("Non-JSON response received");
              }
            }
            // In case there is no body in the response
            return {};
          }),
          catchError((error: any) => {
            // console.error('SSR Error:', error);
            return throwError(error);
          })
        );
    } else if (isPlatformBrowser(this.platformId)) {
      // Browser: Let HttpClient handle the JSON response
      return this.http.get<any>(url).pipe(
        map((user: any) => {
          return user;
        }),
        catchError((error: any) => {
          // console.error('Browser Error:', error);
          return throwError(error);
        })
      );
    }

    // Provide a fallback in case neither condition is met
    return throwError("Unknown platform");
  }
  getContact(): Observable<any> {
    const url = `${environment.apiLoginUrl}/spaLocationST/ `;

    if (isPlatformServer(this.platformId)) {
      // SSR: Handle as text and manually parse JSON
      return this.http
        .get(url, { observe: "response", responseType: "text" })
        .pipe(
          map((response: HttpResponse<string>) => {
            if (response.body) {
              const trimmedData = response.body.trim();
              if (trimmedData.startsWith("{") || trimmedData.startsWith("[")) {
                // Manually parse the JSON and return it
                return JSON.parse(trimmedData);
              } else {
                // Throw an error if the response is not in JSON format
                throw new Error("Non-JSON response received");
              }
            }
            // In case there is no body in the response
            return {};
          }),
          catchError((error: any) => {
            // console.error('SSR Error:', error);
            return throwError(error);
          })
        );
    } else if (isPlatformBrowser(this.platformId)) {
      // Browser: Let HttpClient handle the JSON response
      return this.http.get<any>(url).pipe(
        map((user: any) => {
          return user;
        }),
        catchError((error: any) => {
          // console.error('Browser Error:', error);
          return throwError(error);
        })
      );
    }

    // Provide a fallback in case neither condition is met
    return throwError("Unknown platform");
  }
  getLocations(): Observable<any> {
    const url = `${environment.apiLoginUrl}/spaLocationST/mapLocation`;

    if (isPlatformServer(this.platformId)) {
      // SSR: Handle as text and manually parse JSON
      return this.http
        .get(url, { observe: "response", responseType: "text" })
        .pipe(
          map((response: HttpResponse<string>) => {
            if (response.body) {
              const trimmedData = response.body.trim();
              if (trimmedData.startsWith("{") || trimmedData.startsWith("[")) {
                // Manually parse the JSON and return it
                return JSON.parse(trimmedData);
              } else {
                // Throw an error if the response is not in JSON format
                throw new Error("Non-JSON response received");
              }
            }
            // In case there is no body in the response
            return {};
          }),
          catchError((error: any) => {
            // console.error('SSR Error:', error);
            return throwError(error);
          })
        );
    } else if (isPlatformBrowser(this.platformId)) {
      // Browser: Let HttpClient handle the JSON response
      return this.http.get<any>(url).pipe(
        map((user: any) => {
          return user;
        }),
        catchError((error: any) => {
          // console.error('Browser Error:', error);
          return throwError(error);
        })
      );
    }

    // Provide a fallback in case neither condition is met
    return throwError("Unknown platform");
  }

  
  getCityLocation(): Observable<any> {
    const url = `${environment.apiLoginUrl}/spaLocationST/cityBranch `;

    if (isPlatformServer(this.platformId)) {
      // SSR: Handle as text and manually parse JSON
      return this.http
        .get(url, { observe: "response", responseType: "text" })
        .pipe(
          map((response: HttpResponse<string>) => {
            if (response.body) {
              const trimmedData = response.body.trim();
              if (trimmedData.startsWith("{") || trimmedData.startsWith("[")) {
                // Manually parse the JSON and return it
                return JSON.parse(trimmedData);
              } else {
                // Throw an error if the response is not in JSON format
                throw new Error("Non-JSON response received");
              }
            }
            // In case there is no body in the response
            return {};
          }),
          catchError((error: any) => {
            // console.error('SSR Error:', error);
            return throwError(error);
          })
        );
    } else if (isPlatformBrowser(this.platformId)) {
      // Browser: Let HttpClient handle the JSON response
      return this.http.get<any>(url).pipe(
        map((user: any) => {
          return user;
        }),
        catchError((error: any) => {
          // console.error('Browser Error:', error);
          return throwError(error);
        })
      );
    }

    // Provide a fallback in case neither condition is met
    return throwError("Unknown platform");
  }
  getCelebrityTalks(): Observable<any> {
    const url = `${environment.apiLoginUrl}/celebTalks/ `;

    if (isPlatformServer(this.platformId)) {
      // SSR: Handle as text and manually parse JSON
      return this.http
        .get(url, { observe: "response", responseType: "text" })
        .pipe(
          map((response: HttpResponse<string>) => {
            if (response.body) {
              const trimmedData = response.body.trim();
              if (trimmedData.startsWith("{") || trimmedData.startsWith("[")) {
                // Manually parse the JSON and return it
                return JSON.parse(trimmedData);
              } else {
                // Throw an error if the response is not in JSON format
                throw new Error("Non-JSON response received");
              }
            }
            // In case there is no body in the response
            return {};
          }),
          catchError((error: any) => {
            // console.error('SSR Error:', error);
            return throwError(error);
          })
        );
    } else if (isPlatformBrowser(this.platformId)) {
      // Browser: Let HttpClient handle the JSON response
      return this.http.get<any>(url).pipe(
        map((user: any) => {
          return user;
        }),
        catchError((error: any) => {
          // console.error('Browser Error:', error);
          return throwError(error);
        })
      );
    }

    // Provide a fallback in case neither condition is met
    return throwError("Unknown platform");
  }
  getCity(): Observable<any> {
    const url = `${environment.apiLoginUrl}/spaLocationST/citylist `;

    if (isPlatformServer(this.platformId)) {
      // SSR: Handle as text and manually parse JSON
      return this.http
        .get(url, { observe: "response", responseType: "text" })
        .pipe(
          map((response: HttpResponse<string>) => {
            if (response.body) {
              const trimmedData = response.body.trim();
              if (trimmedData.startsWith("{") || trimmedData.startsWith("[")) {
                // Manually parse the JSON and return it
                return JSON.parse(trimmedData);
              } else {
                // Throw an error if the response is not in JSON format
                throw new Error("Non-JSON response received");
              }
            }
            // In case there is no body in the response
            return {};
          }),
          catchError((error: any) => {
            // console.error('SSR Error:', error);
            return throwError(error);
          })
        );
    } else if (isPlatformBrowser(this.platformId)) {
      // Browser: Let HttpClient handle the JSON response
      return this.http.get<any>(url).pipe(
        map((user: any) => {
          return user;
        }),
        catchError((error: any) => {
          // console.error('Browser Error:', error);
          return throwError(error);
        })
      );
    }

    // Provide a fallback in case neither condition is met
    return throwError("Unknown platform");
  }
  getGiftCard(): Observable<any> {
    // const url = `${environment.apiLoginUrl}/giftCard/getTherapyList `;
    const url = `${environment.apiLoginUrl}/giftCard/getTherapyListNew`;

    if (isPlatformServer(this.platformId)) {
      // SSR: Handle as text and manually parse JSON
      return this.http
        .get(url, { observe: "response", responseType: "text" })
        .pipe(
          map((response: HttpResponse<string>) => {
            if (response.body) {
              const trimmedData = response.body.trim();
              if (trimmedData.startsWith("{") || trimmedData.startsWith("[")) {
                // Manually parse the JSON and return it
                return JSON.parse(trimmedData);
              } else {
                // Throw an error if the response is not in JSON format
                throw new Error("Non-JSON response received");
              }
            }
            // In case there is no body in the response
            return {};
          }),
          catchError((error: any) => {
            // console.error('SSR Error:', error);
            return throwError(error);
          })
        );
    } else if (isPlatformBrowser(this.platformId)) {
      // Browser: Let HttpClient handle the JSON response
      return this.http.get<any>(url).pipe(
        map((user: any) => {
          return user;
        }),
        catchError((error: any) => {
          // console.error('Browser Error:', error);
          return throwError(error);
        })
      );
    }

    // Provide a fallback in case neither condition is met
    return throwError("Unknown platform");
  }
  getProrityDetails(): Observable<any> {
    // const url = `${environment.apiLoginUrl}/giftCard/getTherapyList `;
    const url = `${environment.apiLoginUrl}/cardLoadings/getPlansPriority`;

    if (isPlatformServer(this.platformId)) {
      // SSR: Handle as text and manually parse JSON
      return this.http
        .get(url, { observe: "response", responseType: "text" })
        .pipe(
          map((response: HttpResponse<string>) => {
            if (response.body) {
              const trimmedData = response.body.trim();
              if (trimmedData.startsWith("{") || trimmedData.startsWith("[")) {
                // Manually parse the JSON and return it
                return JSON.parse(trimmedData);
              } else {
                // Throw an error if the response is not in JSON format
                throw new Error("Non-JSON response received");
              }
            }
            // In case there is no body in the response
            return {};
          }),
          catchError((error: any) => {
            // console.error('SSR Error:', error);
            return throwError(error);
          })
        );
    } else if (isPlatformBrowser(this.platformId)) {
      // Browser: Let HttpClient handle the JSON response
      return this.http.get<any>(url).pipe(
        map((user: any) => {
          return user;
        }),
        catchError((error: any) => {
          // console.error('Browser Error:', error);
          return throwError(error);
        })
      );
    }

    // Provide a fallback in case neither condition is met
    return throwError("Unknown platform");
  }
  // APIs With Params

  getTherapiesPackagesByName(itemName: string): Observable<any> {
    // Construct the URL dynamically using the item name
    const formattedItemName = encodeURIComponent(itemName.replace(/-/g, " "));
    const url = `${environment.apiLoginUrl}/therapiesST/getTherapiesByName/${itemName}`;
    // console.log(`Fetching data for: ${itemName}, URL: ${url}`);

    if (isPlatformServer(this.platformId)) {
      // SSR: Handle as text and manually parse JSON
      return this.http
        .get(url, { observe: "response", responseType: "text" })
        .pipe(
          map((response: HttpResponse<string>) => {
            if (response.body) {
              const trimmedData = response.body.trim();
              if (trimmedData.startsWith("{") || trimmedData.startsWith("[")) {
                // Manually parse the JSON and return it
                return JSON.parse(trimmedData);
              } else {
                // Throw an error if the response is not in JSON format
                throw new Error("Non-JSON response received");
              }
            }
            return {}; // If no body in the response
          }),
          catchError((error: any) => {
            console.error("SSR Error:", error);
            return throwError(error);
          })
        );
    } else if (isPlatformBrowser(this.platformId)) {
      // Browser: Let HttpClient handle the JSON response
      return this.http.get<any>(url).pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: any) => {
          console.error("Browser Error:", error);
          return throwError(error);
        })
      );
    }

    return throwError("Unknown platform"); // Fallback in case neither condition is met
  }
  getBlogsDetails(itemName: string): Observable<any> {
    // Construct the URL dynamically using the item name
    const formattedItemName = encodeURIComponent(itemName.replace(/-/g, " "));
    const url = `${environment.apiLoginUrl}/blogST/getBlogByName/${formattedItemName}`;
    // console.log(`Fetching data for: ${itemName}, URL: ${url}`);

    if (isPlatformServer(this.platformId)) {
      // SSR: Handle as text and manually parse JSON
      return this.http
        .get(url, { observe: "response", responseType: "text" })
        .pipe(
          map((response: HttpResponse<string>) => {
            if (response.body) {
              const trimmedData = response.body.trim();
              if (trimmedData.startsWith("{") || trimmedData.startsWith("[")) {
                // Manually parse the JSON and return it
                return JSON.parse(trimmedData);
              } else {
                // Throw an error if the response is not in JSON format
                throw new Error("Non-JSON response received");
              }
            }
            return {}; // If no body in the response
          }),
          catchError((error: any) => {
            console.error("SSR Error:", error);
            return throwError(error);
          })
        );
    } else if (isPlatformBrowser(this.platformId)) {
      // Browser: Let HttpClient handle the JSON response
      return this.http.get<any>(url).pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: any) => {
          console.error("Browser Error:", error);
          return throwError(error);
        })
      );
    }

    return throwError("Unknown platform"); // Fallback in case neither condition is met
  }
  getOfferList(itemName: string): Observable<any> {
    // Construct the URL dynamically using the item name
    const formattedItemName = encodeURIComponent(itemName.replace(/-/g, " "));
    const url = `${environment.apiLoginUrl}/SPAOfferST/specialOffersOfferWiseBranch/${formattedItemName}`;
    // console.log(`Fetching data for: ${itemName}, URL: ${url}`);

    if (isPlatformServer(this.platformId)) {
      // SSR: Handle as text and manually parse JSON
      return this.http
        .get(url, { observe: "response", responseType: "text" })
        .pipe(
          map((response: HttpResponse<string>) => {
            if (response.body) {
              const trimmedData = response.body.trim();
              if (trimmedData.startsWith("{") || trimmedData.startsWith("[")) {
                // Manually parse the JSON and return it
                return JSON.parse(trimmedData);
              } else {
                // Throw an error if the response is not in JSON format
                throw new Error("Non-JSON response received");
              }
            }
            return {}; // If no body in the response
          }),
          catchError((error: any) => {
            console.error("SSR Error:", error);
            return throwError(error);
          })
        );
    } else if (isPlatformBrowser(this.platformId)) {
      // Browser: Let HttpClient handle the JSON response
      return this.http.get<any>(url).pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: any) => {
          console.error("Browser Error:", error);
          return throwError(error);
        })
      );
    }

    return throwError("Unknown platform"); // Fallback in case neither condition is met
  }
  getCityDetail(itemName: string): Observable<any> {
    // Construct the URL dynamically using the item name

    const url = `${environment.apiLoginUrl}/spaLocationST/getDataByBranchCode/${itemName}`;
    // console.log(`Fetching data for: ${itemName}, URL: ${url}`);

    if (isPlatformServer(this.platformId)) {
      // SSR: Handle as text and manually parse JSON
      return this.http
        .get(url, { observe: "response", responseType: "text" })
        .pipe(
          map((response: HttpResponse<string>) => {
            if (response.body) {
              const trimmedData = response.body.trim();
              if (trimmedData.startsWith("{") || trimmedData.startsWith("[")) {
                // Manually parse the JSON and return it
                return JSON.parse(trimmedData);
              } else {
                // Throw an error if the response is not in JSON format
                throw new Error("Non-JSON response received");
              }
            }
            return {}; // If no body in the response
          }),
          catchError((error: any) => {
            console.error("SSR Error:", error);
            return throwError(error);
          })
        );
    } else if (isPlatformBrowser(this.platformId)) {
      // Browser: Let HttpClient handle the JSON response
      return this.http.get<any>(url).pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: any) => {
          console.error("Browser Error:", error);
          return throwError(error);
        })
      );
    }

    return throwError("Unknown platform"); // Fallback in case neither condition is met
  }
  getLocationByName(itemName: string): Observable<any> {
    // Construct the URL dynamically using the item name

    const url = `${environment.apiLoginUrl}/spaLocationST/getDataByLocation/${itemName}`;
    // console.log(`Fetching data for: ${itemName}, URL: ${url}`);

    if (isPlatformServer(this.platformId)) {
      // SSR: Handle as text and manually parse JSON
      return this.http
        .get(url, { observe: "response", responseType: "text" })
        .pipe(
          map((response: HttpResponse<string>) => {
            if (response.body) {
              const trimmedData = response.body.trim();
              if (trimmedData.startsWith("{") || trimmedData.startsWith("[")) {
                // Manually parse the JSON and return it
                return JSON.parse(trimmedData);
              } else {
                // Throw an error if the response is not in JSON format
                throw new Error("Non-JSON response received");
              }
            }
            return {}; // If no body in the response
          }),
          catchError((error: any) => {
            console.error("SSR Error:", error);
            return throwError(error);
          })
        );
    } else if (isPlatformBrowser(this.platformId)) {
      // Browser: Let HttpClient handle the JSON response
      return this.http.get<any>(url).pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: any) => {
          console.error("Browser Error:", error);
          return throwError(error);
        })
      );
    }

    return throwError("Unknown platform"); // Fallback in case neither condition is met
  }
  getDealCode(itemName: string): Observable<any> {
    // Construct the URL dynamically using the item name

    const url = `${environment.apiLoginUrl}/electronicGiftCard/deal/${itemName}`;
    // console.log(`Fetching data for: ${itemName}, URL: ${url}`);

    if (isPlatformServer(this.platformId)) {
      // SSR: Handle as text and manually parse JSON
      return this.http
        .get(url, { observe: "response", responseType: "text" })
        .pipe(
          map((response: HttpResponse<string>) => {
            if (response.body) {
              const trimmedData = response.body.trim();
              if (trimmedData.startsWith("{") || trimmedData.startsWith("[")) {
                // Manually parse the JSON and return it
                return JSON.parse(trimmedData);
              } else {
                // Throw an error if the response is not in JSON format
                throw new Error("Non-JSON response received");
              }
            }
            return {}; // If no body in the response
          }),
          catchError((error: any) => {
            console.error("SSR Error:", error);
            return throwError(error);
          })
        );
    } else if (isPlatformBrowser(this.platformId)) {
      // Browser: Let HttpClient handle the JSON response
      return this.http.get<any>(url).pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: any) => {
          console.error("Browser Error:", error);
          return throwError(error);
        })
      );
    }

    return throwError("Unknown platform"); // Fallback in case neither condition is met
  }
  getPriorityDetails(orderId: any): Observable<any> {
    if (!orderId) {
      // console.error('Error: Missing orderId parameter');
      return throwError('Error: orderId is required for API call');
    }
  
    const url = `${environment.apiLoginUrl}/elctronicPriorityCard/getByOrderId/${orderId}`;
    // console.log('Constructed API URL:', url);
  
    if (isPlatformServer(this.platformId)) {
      // SSR logic
      return this.http
        .get(url, { observe: 'response', responseType: 'text' })
        .pipe(
          map((response: HttpResponse<string>) => {
            if (response.body) {
              const trimmedData = response.body.trim();
              if (trimmedData.startsWith('{') || trimmedData.startsWith('[')) {
                return JSON.parse(trimmedData);
              } else {
                throw new Error('Non-JSON response received');
              }
            }
            return {};
          }),
          catchError((error: any) => {
            console.error('SSR Error:', error);
            return throwError(error);
          })
        );
    } else if (isPlatformBrowser(this.platformId)) {
      // Browser logic
      return this.http.get<any>(url).pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: any) => {
          console.error('Browser Error:', error);
          return throwError(error);
        })
      );
    }
  
    return throwError('Unknown platform'); // Fallback case
  }
  
  

 

  //API for Form
  AppointmentForm(params: any): Observable<any> {
    const url = `${environment.apiLoginUrl}/appointmentST/bookFutureAppointmentWeb`;

    if (isPlatformServer(this.platformId)) {
      // SSR: Handle as text and manually parse JSON
      return this.http.post(url, params, { responseType: "text" }).pipe(
        map((user: any) => {
          try {
            const jsonData = JSON.parse(user);
            return jsonData;
          } catch (error) {
            console.error("Failed to parse JSON in SSR:", error);
            throw error;
          }
        }),
        catchError((error: any) => {
          console.error("SSR Error:", error);
          return throwError(error);
        })
      );
    } else if (isPlatformBrowser(this.platformId)) {
      // Browser: Let HttpClient handle the JSON response
      return this.http.post<any>(url, params).pipe(
        map((user: any) => {
          return user;
        }),
        catchError((error: any) => {
          console.error("Browser Error:", error);
          return throwError(error);
        })
      );
    } else {
      // Fallback in case neither condition is true
      console.error("Unsupported platform");
      return throwError("Unsupported platform");
    }
  }
  insertForm(params: any): Observable<any> {
    const url = `${environment.apiLoginUrl}/electronicGiftCard/insert`;

    if (isPlatformServer(this.platformId)) {
      // SSR: Handle as text and manually parse JSON
      return this.http.post(url, params, { responseType: "text" }).pipe(
        map((user: any) => {
          try {
            const jsonData = JSON.parse(user);
            return jsonData;
          } catch (error) {
            console.error("Failed to parse JSON in SSR:", error);
            throw error;
          }
        }),
        catchError((error: any) => {
          console.error("SSR Error:", error);
          return throwError(error);
        })
      );
    } else if (isPlatformBrowser(this.platformId)) {
      // Browser: Let HttpClient handle the JSON response
      return this.http.post<any>(url, params).pipe(
        map((user: any) => {
          return user;
        }),
        catchError((error: any) => {
          console.error("Browser Error:", error);
          return throwError(error);
        })
      );
    } else {
      // Fallback in case neither condition is true
      console.error("Unsupported platform");
      return throwError("Unsupported platform");
    }
  }
  confirmation(id: string, email: string): Observable<any> {
    const url = `${environment.apiLoginUrl}/electronicGiftCard/details?id=${id}&email=${email}`;

    if (isPlatformServer(this.platformId)) {
      // SSR: Handle as text and manually parse JSON
      return this.http.get(url, { responseType: "text" }).pipe(
        map((user: any) => {
          try {
            const jsonData = JSON.parse(user);
            return jsonData;
          } catch (error) {
            console.error("Failed to parse JSON in SSR:", error);
            throw error;
          }
        }),
        catchError((error: any) => {
          console.error("SSR Error:", error);
          return throwError(error);
        })
      );
    } else if (isPlatformBrowser(this.platformId)) {
      // Browser: Let HttpClient handle the JSON response
      return this.http.get<any>(url).pipe(
        map((user: any) => {
          return user;
        }),
        catchError((error: any) => {
          console.error("Browser Error:", error);
          return throwError(error);
        })
      );
    } else {
      // Fallback in case neither condition is true
      console.error("Unsupported platform");
      return throwError("Unsupported platform");
    }
  }
  encryptionKey(params: any): Observable<any> {
    const url = `${environment.apiLoginUrl}/electronicGiftCard/encrypt`;

    if (isPlatformServer(this.platformId)) {
      // SSR: Handle as text and manually parse JSON
      return this.http.post(url, params, { responseType: "text" }).pipe(
        map((user: any) => {
          try {
            const jsonData = JSON.parse(user);
            return jsonData;
          } catch (error) {
            console.error("Failed to parse JSON in SSR:", error);
            throw error;
          }
        }),
        catchError((error: any) => {
          console.error("SSR Error:", error);
          return throwError(error);
        })
      );
    } else if (isPlatformBrowser(this.platformId)) {
      // Browser: Let HttpClient handle the JSON response
      return this.http.post<any>(url, params).pipe(
        map((user: any) => {
          return user;
        }),
        catchError((error: any) => {
          console.error("Browser Error:", error);
          return throwError(error);
        })
      );
    } else {
      // Fallback in case neither condition is true
      console.error("Unsupported platform");
      return throwError("Unsupported platform");
    }
  }
 
  genrateOTP(mobileNumber: any): Observable<any> {
    const url = `${environment.apiLoginUrl}/appointmentST/genOTP`;

    if (isPlatformServer(this.platformId)) {
      // SSR: Handle as text and manually parse JSON
      return this.http.post(url, mobileNumber, { responseType: "text" }).pipe(
        map((user: any) => {
          try {
            const jsonData = JSON.parse(user);
            return jsonData;
          } catch (error) {
            console.error("Failed to parse JSON in SSR:", error);
            throw error;
          }
        }),
        catchError((error: any) => {
          console.error("SSR Error:", error);
          return throwError(error);
        })
      );
    } else if (isPlatformBrowser(this.platformId)) {
      // Browser: Let HttpClient handle the JSON response
      return this.http.post<any>(url, mobileNumber).pipe(
        map((user: any) => {
          return user;
        }),
        catchError((error: any) => {
          console.error("Browser Error:", error);
          return throwError(error);
        })
      );
    } else {
      // Fallback in case neither condition is true
      console.error("Unsupported platform");
      return throwError("Unsupported platform");
    }
  }
  verifyOTP(OTP:any): Observable<any> {
    const url = `${environment.apiLoginUrl}/appointmentST/verifyOTP`;

    if (isPlatformServer(this.platformId)) {
      // SSR: Handle as text and manually parse JSON
      return this.http.post(url, OTP, { responseType: "text" }).pipe(
        map((user: any) => {
          try {
            const jsonData = JSON.parse(user);
            return jsonData;
          } catch (error) {
            console.error("Failed to parse JSON in SSR:", error);
            throw error;
          }
        }),
        catchError((error: any) => {
          console.error("SSR Error:", error);
          return throwError(error);
        })
      );
    } else if (isPlatformBrowser(this.platformId)) {
      // Browser: Let HttpClient handle the JSON response
      return this.http.post<any>(url, OTP).pipe(
        map((user: any) => {
          return user;
        }),
        catchError((error: any) => {
          console.error("Browser Error:", error);
          return throwError(error);
        })
      );
    } else {
      // Fallback in case neither condition is true
      console.error("Unsupported platform");
      return throwError("Unsupported platform");
    }
  }
  encryptionForPrority(params: any): Observable<any> {
    const url = `${environment.apiLoginUrl}/elctronicPriorityCard/encrypt`;

    if (isPlatformServer(this.platformId)) {
      // SSR: Handle as text and manually parse JSON
      return this.http.post(url, params, { responseType: "text" }).pipe(
        map((user: any) => {
          try {
            const jsonData = JSON.parse(user);
            return jsonData;
          } catch (error) {
            console.error("Failed to parse JSON in SSR:", error);
            throw error;
          }
        }),
        catchError((error: any) => {
          console.error("SSR Error:", error);
          return throwError(error);
        })
      );
    } else if (isPlatformBrowser(this.platformId)) {
      // Browser: Let HttpClient handle the JSON response
      return this.http.post<any>(url, params).pipe(
        map((user: any) => {
          return user;
        }),
        catchError((error: any) => {
          console.error("Browser Error:", error);
          return throwError(error);
        })
      );
    } else {
      // Fallback in case neither condition is true
      console.error("Unsupported platform");
      return throwError("Unsupported platform");
    }
  }

  //API for PriorityCard http://172.16.6.22:5002/route/elctronicPriorityCard/insert
  insertPriorityForm(params: any): Observable<any> {
    const url = `${environment.apiLoginUrl}/elctronicPriorityCard/insert`;

    if (isPlatformServer(this.platformId)) {  
      // SSR: Handle as text and manually parse JSON
      return this.http.post(url, params, { responseType: "text" }).pipe(
        map((user: any) => {
          try {
            const jsonData = JSON.parse(user);
            return jsonData;
          } catch (error) {
            console.error("Failed to parse JSON in SSR:", error);
            throw error;
          }
        }),
        catchError((error: any) => {
          console.error("SSR Error:", error);
          return throwError(error);
        })
      );
    } else if (isPlatformBrowser(this.platformId)) {
      // Browser: Let HttpClient handle the JSON response
      return this.http.post<any>(url, params).pipe(
        map((user: any) => {
          return user;
        }),
        catchError((error: any) => {
          console.error("Browser Error:", error);
          return throwError(error);
        })
      );
    } else {
      // Fallback in case neither condition is true
      console.error("Unsupported platform");
      return throwError("Unsupported platform");
    }
  }
  getOnlineBookData(orderId: any): Observable<any> {
    const url = `${environment.apiLoginUrl}/onlinePay/getNewOrder`;

    if (isPlatformServer(this.platformId)) {
      // SSR: Handle as text and manually parse JSON
      return this.http
        .post(url, { orderId }, { observe: "response", responseType: "text" })
        .pipe(
          map((response: HttpResponse<string>) => {
            if (response.body) {
              const trimmedData = response.body.trim();
              if (trimmedData.startsWith("{") || trimmedData.startsWith("[")) {
                // Manually parse the JSON and return it
                return JSON.parse(trimmedData);
              } else {
                // Throw an error if the response is not in JSON format
                throw new Error("Non-JSON response received");
              }
            }
            return {}; // If no body in the response
          }),
          catchError((error: any) => {
            console.error("SSR Error:", error);
            return throwError(error);
          })
        );
    } else if (isPlatformBrowser(this.platformId)) {
      // Browser: Let HttpClient handle the JSON response
      return this.http.post<any>(url, { orderId }).pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: any) => {
          console.error("Browser Error:", error);
          return throwError(error);
        })
      );
    }

    return throwError("Unknown platform"); // Fallback in case neither condition is met
}
callPaymentAPI(orderId: any): Observable<any> {
  const url = `${environment.apiLoginUrl}/onlinePay/createPayment
  `;

  if (isPlatformServer(this.platformId)) {
    // SSR: Handle as text and manually parse JSON
    return this.http
      .post(url, { orderId }, { observe: "response", responseType: "text" })
      .pipe(
        map((response: HttpResponse<string>) => {
          if (response.body) {
            const trimmedData = response.body.trim();
            if (trimmedData.startsWith("{") || trimmedData.startsWith("[")) {
              // Manually parse the JSON and return it
              return JSON.parse(trimmedData);
            } else {
              // Throw an error if the response is not in JSON format
              throw new Error("Non-JSON response received");
            }
          }
          return {}; // If no body in the response
        }),
        catchError((error: any) => {
          console.error("SSR Error:", error);
          return throwError(error);
        })
      );
  } else if (isPlatformBrowser(this.platformId)) {
    // Browser: Let HttpClient handle the JSON response
    return this.http.post<any>(url, { orderId }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => {
        console.error("Browser Error:", error);
        return throwError(error);
      })
    );
  }

  return throwError("Unknown platform"); // Fallback in case neither condition is met
}


  // get-set methods /appointmentST/verifyOTP

  setBranchCode(code: string): void {
    this.branchCode = code;
  }

  getBranchCode(): string {
    return this.branchCode;
  }
  setResponseData(data: any) {
    this.responseData = data;
  }

  getResponseData() {
    return this.responseData;
  }

  feedBackForm(params: any): Observable<any> {
    const url = `${environment.apiLoginUrl}/slideShowSt`;

    if (isPlatformServer(this.platformId)) {
      // SSR: Handle as text and manually parse JSON
      return this.http.post(url, params, { responseType: "text" }).pipe(
        map((user: any) => {
          try {
            const jsonData = JSON.parse(user);
            return jsonData;
          } catch (error) {
            console.error("Failed to parse JSON in SSR:", error);
            throw error;
          }
        }),
        catchError((error: any) => {
          console.error("SSR Error:", error);
          return throwError(error);
        })
      );
    } else if (isPlatformBrowser(this.platformId)) {
      // Browser: Let HttpClient handle the JSON response
      return this.http.post<any>(url, params).pipe(
        map((user: any) => {
          return user;
        }),
        catchError((error: any) => {
          console.error("Browser Error:", error);
          return throwError(error);
        })
      );
    } else {
      // Fallback in case neither condition is true
      console.error("Unsupported platform");
      return throwError("Unsupported platform");
    }
  }

  // seo meta-data
  getMetaTitle(params: any): Observable<any> {
    const url = `${environment.apiLoginUrl}/webmetatag/searchwebmetatag`;

    if (isPlatformServer(this.platformId)) {
      // SSR: Handle as text and manually parse JSON
      return this.http.post(url, params, { responseType: "text" }).pipe(
        map((user: any) => {
          try {
            const jsonData = JSON.parse(user);
            return jsonData;
          } catch (error) {
            console.error("Failed to parse JSON in SSR:", error);
            throw error;
          }
        }),
        catchError((error: any) => {
          console.error("SSR Error:", error);
          return throwError(error);
        })
      );
    } else if (isPlatformBrowser(this.platformId)) {
      // Browser: Let HttpClient handle the JSON response
      return this.http.post<any>(url, params).pipe(
        map((user: any) => {
          return user;
        }),
        catchError((error: any) => {
          console.error("Browser Error:", error);
          return throwError(error);
        })
      );
    } else {
      // Fallback in case neither condition is true
      console.error("Unsupported platform");
      return throwError("Unsupported platform");
    }
  }
}
