export const environment = {
    production: false,
    baseUrl:'https://www.sukhothai.in',
    CCAvenueMerchantId: '9243',
    CCAvenueWorkingKey: '69CDC972A16C7B74D14EE2AFD790E230',
    CCAccessCode :'AVHO71JB29BJ97OHJB',
    hmr: false,
    jsonIp: 'https://jsonip.com',
    ipify: 'https://api.ipify.org/?format=json',
    ifconfig: 'https://ifconfig.co',
    // apiUrl: 'https://api.kesari.in',
    // apiLoginUrl: 'http://login.sukhothai.in/route',
    apiLoginUrl:'https://www.sukhothai.in/api',
    arunUrl:'http://172.16.6.22:5002/route',
    apibetaAwsUrl: 'https://api.beta.kesari.in',
    // apiUrl    : '/apis',
    vivkApi: 'http://172.16.6.86:3000',
    kuldeepApi: 'http://172.16.6.63:5002/route',
    sushmaApi: 'http://172.16.6.109:3000',
    sushmaNewApi: 'http://172.16.6.110:5002/route',
    arunAPI:'http://172.16.6.22:5002/route',
    auth: {

    }
};
